var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "pa-4" }, [_c("canvas", { ref: "myChart" })]),
    _c("div", [_vm._v(_vm._s(_vm.options.legendTitle))]),
    _c("ul", {
      ref: "legend-container",
      staticClass: "d-flex flex-column pt-2 chartjs-legend text-left",
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }